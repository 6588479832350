import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import classNames from "classnames";
import { AddressSuggestions } from 'react-dadata';
import 'react-dadata/dist/react-dadata.css';
import { useDebouncedCallback } from "use-debounce";
import TextMaskCustom from "../Form/TextMaskCustom";
import useLocalStorage from "../../hooks/useLocalStorege";

import {
  clearCart,
  checkPromocode,
  activatePromocode,
  setTypePickup,
  addToCart,
} from "../../redux/actions/cart";

import Button from "../Button/Button";
import Modal from "../Modal/Modal";
import DeliveryInfo from "../Modal/ModalBody/DeliveryInfo";
import CartItem from "../CartItem/CartItem";
import CartSlider from "../CartSlider/CartSlider";
import './CartOrder.scss';

import deliveryIcon from "../../assets/img/delivery-icon.svg";
import storeIcon from "../../assets/img/storefront-icon.svg";
import timeIcon from "../../assets/img/time-icon.svg";
import specificTimeIcon from "../../assets/img/specific-time-icon.svg";
import moneyIcon from "../../assets/img/money-icon.svg";
import creditCardIcon from "../../assets/img/credit-card-icon.svg";

const phoneRegExp =
  // eslint-disable-next-line no-useless-escape
  /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10,11}$/;

function calculate({
  startTime = moment().add(60 - (moment().minute() % 15), "m"),
  endTime,
}) {
  const timeStops = [];

  while (startTime <= endTime) {
    // eslint-disable-next-line new-cap
    timeStops.push(new moment(startTime).format("HH:mm"));
    startTime.add(15, "m");
  }

  return timeStops;
}

const validationSchema1 = yup.object({
  name: yup
    .string()
    .min(2, "Имя")
    .max(50, "Имя")
    .typeError("Имя")
    .required("Введите имя"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Проверьте корректность введенного номера")
    .required("Введите номер телефона"),
  address: yup.string().required("Введите адрес доставки"),
  apartment: yup
    .number()
    .typeError("Введите кв/офис")
    .required("Введите кв/офис")
    .integer("Введите кв/офис"),
});

const validationSchema2 = yup.object({
  name: yup
    .string()
    .min(2, "Имя")
    .max(50, "Имя")
    .typeError("Имя")
    .required("Введите имя"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Проверьте корректность введенного номера")
    .required("Введите номер телефона"),
});

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  formControl: {
    margin: theme.spacing(2),
    marginRight: 0,
    marginLeft: 0,
    width: "100%",
  },
  formTimeControl: {
    margin: 0,
    width: "100%",
  },
  select: {
    width: "100%",
  },
  textInput: {
    width: "100%",

    input: {
      width: "100%",
    },
  },
}));

const ITEM_HEIGHT = 48;

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  style: {
    maxHeight: ITEM_HEIGHT * 6,
  },
  variant: "menu",
};

let timeArray = calculate({ endTime: moment().endOf("day") });
let initialTime = timeArray[0];

const CartOrder = ({
  cartItems,
  totalPrice,
  totalPriceWithDiscount,
  handleSubmit: setSubmited,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cart = useSelector((state) => state.cart);
  const [wayToGet, setWayToGet] = useState("Доставка");
  const [deliveryDay, setDeliveryDay] = useState("Сегодня");
  const [deliveryTime, setDeliveryTime] = useState("Ближайшее время");
  const [save, setSave] = useLocalStorage("save_cheackbox", true);
  const [nameOrder, setNameOrder] = useLocalStorage("save_name", "");
  const [phoneOrder, setPhoneOrder] = useLocalStorage("save_phone", "");
  // const [adressOrder, setAdressOrder] = useState(localStorage.getItem("save_address1") || '')
  const [adressOrder, setAdressOrder] = useState(localStorage.getItem("save_address1") || '');
  const [apartmentOrder, setApartmentOrder] = useLocalStorage("save_apartment", "");
  const [entranceOrder, setEntranceOrder] = useLocalStorage("save_entrance", "");
  const [floorOrder, setFloorOrder] = useLocalStorage("save_floor", "");
  const [entranceCodeOrder, setEntranceCodeOrder] = useLocalStorage("save_entranceCode", "");
  const [commentOrder, setCommentOrder] = useLocalStorage("save_comment", "");
  const [products, setProducts] = useState([]);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [deliveryError, setDeliveryError] = useState(false);

  const [missingAmount, setMissingAmount] = useState(0);
  const [missingPopup, setMissingPopup] = useState(false);
  const [zonePopup, setZonePopup] = useState(false);
  const [orderError, setOrderError] = useState("");

  const [birthday, setBirthday] = useState(false);

  const [timePopup, setTimePopup] = useState(false);

  const history = useHistory();

  const checkPriceStatus = (id, itemBasket, items, tastes, sizes) => {

    console.log(itemBasket)


    if (itemBasket.size == '') {


      const prevProduct = itemBasket.price;
      const tastePrevProduct = itemBasket.taste.length > 0 ? tastes.find((item) => item.name === itemBasket.taste).name : '';


      let currentProduct = items.find((item) => item._id === id).cost;


      if (tastePrevProduct !== "") {
        currentProduct += tastes.find((item) => item.name === itemBasket.taste).price;
      }


      if (prevProduct !== currentProduct) {

        return false
      }


      return true;
    } else {

      let currentProduct = 0;

      Object.keys(items.find((item) => item._id === itemBasket.mainProduct).size).map((id) => {

        if (itemBasket.size === id) {

          currentProduct = items.find((item) => item._id === itemBasket.mainProduct).size[id][0].price

        }




        return null;
      })




      const tastePrevProduct = itemBasket.taste.length > 0 ? tastes.find((item) => item.name === itemBasket.taste).name : '';




      let prevProduct = itemBasket.price;


      if (tastePrevProduct !== "") {
        currentProduct += tastes.find((item) => item.name === itemBasket.taste).price;
      }




      if (prevProduct !== currentProduct) {

        return false
      }
      return true;
    }

  }

  const fetchCheckProduct = async (act = '') => {
    return true;
    let different = false;
    // console.log(cartItems)
    // dispatch(clearCart());

    try {

      const items = await fetch('/api/products').then(response => response.json())
      const tastes = await fetch('/api/options').then(response => response.json())
      const sizes = await fetch('/api/sizes').then(response => response.json())



      Object.keys(cartItems).map((id) => {

        if (!checkPriceStatus(id, cartItems[id], items, tastes, sizes)) {

          different = true
        }
        return null;
      })


      if (different) {
        dispatch(clearCart());


        Object.keys(cartItems).map((id) => {

          let pricePrevProduct = 0
          if (cartItems[id].size == '') {
            pricePrevProduct = items.find((item) => item._id === id).cost;
          } else {


            Object.keys(items.find((item) => item._id === cartItems[id].mainProduct).size).map((id1) => {

              if (cartItems[id].size === id1) {

                pricePrevProduct = items.find((item) => item._id === cartItems[id].mainProduct).size[id1][0].price

              }




              return null;
            })
          }




          const tastePrevProduct = cartItems[id].taste.length > 0 ? tastes.find((item) => item.name === cartItems[id].taste).name : '';

          if (tastePrevProduct !== "") {
            pricePrevProduct += tastes.find((item) => item.name === cartItems[id].taste).price;
          }


          dispatch(addToCart({ id, price: pricePrevProduct, mainProduct: cartItems[id].mainProduct, count: cartItems[id].count, size: cartItems[id].size, name: cartItems[id].name, image: cartItems[id].image, taste: tastePrevProduct, type: 'changeActualPrice' }));

          return null
        })


        if (act === 'button') {
          alert("Дорогой гость! Пока Ваш заказ находился в корзине, были внесены изменения в меню и цены на сайте. При продолжении оформления заказа страница перезагрузится и цены будут автоматически изменены")
          window.location.reload();
        }

        different = false;

        return false;
      }

      return true


    } catch (error) {
      dispatch(clearCart());
      throw new Error(error)
    }

  }




  useEffect(() => {
    console.log(cartItems);
    dispatch(setTypePickup(false));
    dispatch(activatePromocode(false));
    window.scrollTo(0, 0)
    fetchCheckProduct()
  }, []);



  const formik = useFormik({
    initialValues: {
      delivery: "Доставка",
      name: nameOrder,
      phone: phoneOrder,
      address: adressOrder,
      apartment: apartmentOrder,
      promocode: "",
      entranceCode: entranceCodeOrder,
      entrance: entranceOrder,
      floor: floorOrder,
      comment: commentOrder,
      day: "Сегодня",
      time: "Ближайшее время",
      specificTime: initialTime,
      payment: "cache",
    },
    validationSchema:
      wayToGet === "Доставка" ? validationSchema1 : validationSchema2,
    onSubmit: async (values, actions) => {
      if (birthday && totalPrice<1000 && values.payment!="online") {
        alert("Минимальная сумма заказа в День Рождения 1000₽!");
        return;
      }
      let shop_opened = await checkShopOpened();
      // shop_opened = true;
      
      if (!shop_opened) {
        setTimePopup(true);
        return;
      }
      console.log(deliveryError);
      if (values.delivery == "Доставка" && deliveryError) {
        if (missingAmount > 0) {
          setMissingPopup(true);
        }
        if (orderError == "zone_error") {
          setZonePopup(true);
        }
        return;
      }
      const items = Object.keys(cart.items);
      if (save) {
        setNameOrder(values.name)
        setPhoneOrder(values.phone)

        // setAdressOrder(values.address)
        localStorage.setItem("save_address1", values.address)
        setApartmentOrder(values.apartment)
        setFloorOrder(values.floor)
        // setCommentOrder(values.comment)
        setEntranceCodeOrder(values.entranceCode)
        setEntranceOrder(values.entrance)
      }
      window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          purchase: {
            actionField: {
              id: "TRX987",
            },
            products: items.map((key) => {
              const { name, totalPrice: price, count, taste } = cart.items[key];
              return {
                id: key,
                name,
                price,
                quantity: count,
                taste
              };
            }),
          },
        },
      });

      values.order = cart;

      if (await fetchCheckProduct('button')) {

        // fetch("/api/mail", {
        //   method: "POST",
        //   headers: { "Content-Type": "application/json; charset=utf-8" },
        //   body: JSON.stringify(values, null, 2),
        // });

        // console.log(values);
        let ordertype = "";
        if (values.delivery == "Доставка") {
          ordertype = "courier"
        } else {
          ordertype = "self"
        };
        let delivery_type = "";
        if (values.time == "Определенное время") {
          delivery_type = "time"
        } else {
          delivery_type = "asap"
        }

        let items_to_api = [];

        for (var key in cart.items) {
          let item = cart.items[key];
          items_to_api.push({
            "external_id": 0,
            "name": item.name,
            "price": item.price,
            "quantity": item.count,
            "taste": item.taste || ""
          });
        };
        let info_to_api = {
          "type": ordertype,
          "name": values.name,
          "phone": values.phone,
          "address": values.address,
          "apartment": values.apartment,
          "flat": values.floor,
          "code": values.entranceCode,
          "gate": values.entrance,
          "comment": values.comment,
          "delivery_type": delivery_type,
          // "time": values.day,  
          "payment_method": values.payment,
          "promo": values.promocode,
          "delivery_price": deliveryPrice,
          "price_with_discount": countTotalPrice() + deliveryPrice,
          "items": items_to_api
        };
        if (birthday && totalPrice>=1000 && values.payment!="online"){
          info_to_api.comment = info_to_api.comment + "(СКИДКА ИМЕНИННИКУ)"
        }
        if (info_to_api.delivery_type == "time") {
          info_to_api.time = values.day + " " + values.specificTime;
        }

        // console.log(info_to_api);
        fetch("https://api.xlfood.ru/v1/order", {
          method: "POST",
          headers: { "Content-Type": "application/json; charset=utf-8" },
          body: JSON.stringify(info_to_api, null, 2),
        }).then(async (data) => {
          const json = await data.json();
          if (json["link"]) {
            window.open(json["link"], "_blank");
            setSubmited(true);
            dispatch(clearCart());
            actions.resetForm();
            history.push("/success");
          } else {
            alert("Произошла ошибка, проверьте введённые данные или повторите попытку позже");
          };
        });
      };
    },
  });

  const makeOrder = () => {
    let delivery_type = "";
    if (formik.values.time == "Определенное время") {
      delivery_type = "time"
    } else {
      delivery_type = "asap"
    }

    let items_to_api = [];

    for (var key in cart.items) {
      let item = cart.items[key];
      items_to_api.push({
        "external_id": 0,
        "name": item.name,
        "price": item.price,
        "quantity": item.count,
        "taste": item.taste || ""
      });
    };
    let info_to_api = {
      "type": "courier",
      "name": formik.values.name,
      "phone": formik.values.phone,
      "address": formik.values.address,
      "apartment": formik.values.apartment,
      "flat": formik.values.floor,
      "code": formik.values.entranceCode,
      "gate": formik.values.entrance,
      "comment": formik.values.comment,
      "delivery_type": delivery_type,
      "payment_method": formik.values.payment,
      "promo": formik.values.promocode,
      "delivery_price": missingAmount,
      "price_with_discount": countTotalPrice() + missingAmount,
      "items": items_to_api
    };
    if (birthday && totalPrice>=1000 && formik.values.payment!="online"){
      info_to_api.comment = info_to_api.comment + "(СКИДКА ИМЕНИННИКУ)"
    }
    if (info_to_api.delivery_type == "time") {
      info_to_api.time = formik.values.day + " " + formik.values.specificTime;
    }

    // console.log(info_to_api);
    fetch("https://api.xlfood.ru/v1/order", {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify(info_to_api, null, 2),
    }).then(async (data) => {
      const json = await data.json();
      if (json["link"]) {
        window.open(json["link"], "_blank");
        setSubmited(true);
        dispatch(clearCart());
        // formik.actions.resetForm();
        history.push("/success");
      } else {
        alert("Произошла ошибка, проверьте введённые данные или повторите попытку позже");
      };
    });
  }

  const handleWayToGet = (e) => {
    setWayToGet(e.target.value);

    if (e.target.value === "Самовывоз") dispatch(setTypePickup(true));
    if (e.target.value === "Доставка") dispatch(setTypePickup(false));

    formik.setFieldValue("delivery", e.target.value);
  };
  const handleDeliveryDay = (e) => {
    setDeliveryDay(e.target.value);
    formik.setFieldValue("day", e.target.value);
    if (e.target.value === "Сегодня") {
      timeArray = calculate({ endTime: moment().endOf("day") });
      initialTime = timeArray[0];
      formik.setFieldValue("specificTime", initialTime);
    }
    if (e.target.value === "Завтра") {
      timeArray = calculate({
        startTime: moment().startOf("day"),
        endTime: moment().endOf("day"),
      });
      initialTime = timeArray[0];
      formik.setFieldValue("specificTime", initialTime);
    }
  };
  const handleDeliveryTime = (e) => {
    setDeliveryTime(e.target.value);
    formik.setFieldValue("time", e.target.value);
  };
  const handleSpecificTime = (e) => {
    if (deliveryTime === "Ближайшее время") {
      formik.setFieldValue("specificTime", "");
    }
    if (deliveryTime === "Определенное время") {
      formik.setFieldValue("specificTime", e.target.value);
    }
  };
  const handleChangePromocode = useDebouncedCallback((value) => {
    dispatch(checkPromocode(value));
  }, 250);
  const handleChangeCheackBox = (e) => {

    setSave(e.target.checked);

    if (e.target.checked === false) {
      setNameOrder("")
      setPhoneOrder("")
      setAdressOrder("")
      setApartmentOrder("")
      setFloorOrder("")
      setCommentOrder("")
      setEntranceCodeOrder("")
      setEntranceOrder("")
    }
  };

  useEffect(() => {
    if (
      !cart.promoCodeIsValid &&
      !cart.promoCodeLoading &&
      formik.values.promocode.length > 0
    ) {
      formik.setFieldError("promocode", "Промокод недействителен");
    }
  }, [formik.values.promocode, cart.promoCodeLoading]);

  useEffect(() => {
    let info_to_api = {
      address: formik.values.address,
      amount: totalPrice
    };
    fetch("https://api.xlfood.ru/v1/count-price", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(info_to_api),
    }).then(async (data) => {
      let resp = await data.json();
      if (resp["result"] == true) {
        setDeliveryPrice(resp["price"] || 0);
        setDeliveryError(false);
        setOrderError("");
        setMissingAmount(0);
      } else {
        if (resp["error"]) {
          setOrderError(resp["error"])
        } else {
          setOrderError("");
        }
        setDeliveryError(true);
        setMissingAmount(resp["missing_amount"])
      }
    }).catch((reason) => {
      setDeliveryPrice(0);
      setDeliveryError(true);
    })

  }, [adressOrder, formik.values.address, cartItems]);

  const checkShopOpened = async function () {
    let resp = await fetch("https://api.xlfood.ru/v1/get_time");
    let json = await resp.json();
    return json["result"];
  };

  const countTotalPrice = function () {
    if (formik.values.payment == "cache" || formik.values.payment == "card") {
      if (birthday) {
        if(wayToGet == "Доставка"){
          return totalPrice * 0.9;
        }else{
          return totalPrice * 0.8;
        }
      }
    }
    return totalPriceWithDiscount;
  };

  return (
    <section className="cart">
      <Grid className="cart__container" container>
        <Grid className="cart__column" item md={6} sm={8} xs={12}>
          <h2 className="cart__title">Оформление заказа</h2>

          <form onSubmit={formik.handleSubmit}>
            <FormControl className={classes.formControl}>
              <div className="select-container">
                {formik.values.delivery === "Доставка" && (
                  <img src={deliveryIcon} alt="Доставка" />
                )}
                {formik.values.delivery === "Самовывоз" && (
                  <img src={storeIcon} alt="Самовывоз" />
                )}
                <div>
                  <Select
                    value={formik.values.delivery}
                    name="delivery"
                    className={classes.select}
                    MenuProps={menuProps}
                    id="delivery"
                    onChange={handleWayToGet}
                  >
                    <MenuItem value="Доставка">Доставка</MenuItem>
                    <MenuItem value="Самовывоз">
                      Самовывоз / скидка 15 %
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </FormControl>

            <TextField
              label="Имя"
              name="name"
              className={classes.textInput}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}

            />

            <TextField

              margin="normal"
              className={classes.textInput}
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="phone"
              id="phone-input"
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              InputProps={{
                inputComponent: TextMaskCustom
              }}
            />
            <p style={{ color: "#FFBA00", marginBottom: 15 }}>Внимание! Вводить номер нужно без кода страны (+7 или 8)</p>

            {formik.values.delivery === "Доставка" && (
              <>
                <AddressSuggestions

                  token="08f01942f4a003c0f5a4a4b18d3b8d9acf8598f3"
                  filterLocations={[{
                    "city_fias_id": "9b968c73-f4d4-4012-8da8-3dacd4d4c1bd"
                  }]}

                  onChange={(e) => { formik.setFieldValue("address", e.value); setAdressOrder(e.value) }}

                  onBlur={formik.handleBlur}
                  name="address"
                  margin="normal"

                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                  // defaultQuery={localStorage.getItem("save_address1") && localStorage.getItem("save_address1")}
                  defaultQuery={adressOrder}
                  className={classes.textInput}
                  inputProps={{
                    'placeholder': 'Адрес доставки (Улица, дом)',
                    'onChange': (e) => formik.setFieldValue("address", e.target.value),
                    'className': `react-dadata__input ${classes.textInput} ${formik.touched.address && 'react-dadata__input__error'}`
                  }}

                />




                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      label="Кв/офис"
                      name="apartment"
                      value={formik.values.apartment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.apartment &&
                        Boolean(formik.errors.apartment)
                      }
                      helperText={
                        formik.touched.apartment && formik.errors.apartment
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Домофон"
                      name="entranceCode"
                      value={formik.values.entranceCode}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Подъезд"
                      name="entrance"
                      value={formik.values.entrance}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="Этаж"
                      name="floor"
                      value={formik.values.floor}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            <TextField
              label="Комментарий"
              name="comment"
              helperText="Укажите дополнительную
              информацию или пожелания к заказу"
              margin="normal"
              className={classes.textInput}
              value={formik.values.comment}
              onChange={formik.handleChange}
            />
            <FormGroup>

              <FormControlLabel
                control={<Checkbox checked={save} onChange={handleChangeCheackBox} />}
                label="Сохранить для следующего заказа" />

            </FormGroup>
            <TextField
              label="Промокод"
              id="promocode"
              name="promocode"
              margin="normal"
              className={classes.textInput}
              value={formik.values.promocode}
              onChange={(e) => {
                formik.handleChange(e);
                handleChangePromocode(e.target.value);
              }}
              error={!cart.promoCodeIsValid && Boolean(formik.errors.promocode)}
              helperText={!cart.promoCodeIsValid && formik.errors.promocode}
            />

            <FormControl className={classes.formControl}>
              <div className="select-container">
                {formik.values.time === "Ближайшее время" && (
                  <img src={timeIcon} alt="Ближайшее время" />
                )}
                {formik.values.time === "Определенное время" && (
                  <img src={specificTimeIcon} alt="Определенное время" />
                )}
                <div>
                  <InputLabel id="label-time" htmlFor="select-time" />
                  <Select
                    className={classes.select}
                    labelId="label-time"
                    name="time"
                    id="select-time"
                    MenuProps={menuProps}
                    value={formik.values.time}
                    onChange={handleDeliveryTime}
                  >
                    <MenuItem value="Ближайшее время">
                      Заказ на ближайшее время
                    </MenuItem>
                    <MenuItem value="Определенное время">
                      Заказ на определенное время
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </FormControl>

            {formik.values.time === "Определенное время" && (
              <div className="delivery-time">
                <p className="delivery-time__info">
                  Укажите желаемое время самовывоза{" "}
                  <b>в вашем часовом поясе (Asia/Krasnoyarsk)</b>
                </p>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={5}>
                    <div className="delivery-time__buttons">
                      <Button
                        value="Сегодня"
                        className={classNames("button", {
                          active: deliveryDay === "Сегодня",
                        })}
                        onClick={handleDeliveryDay}
                      >
                        Сегодня
                      </Button>
                      <Button
                        value="Завтра"
                        className={classNames({
                          active: deliveryDay === "Завтра",
                        })}
                        onClick={handleDeliveryDay}
                      >
                        Завтра
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl className={classes.formTimeControl}>
                      <Select
                        className={classes.select}
                        labelId="label-specific-time"
                        name="specificTime"
                        id="select-specific-time"
                        MenuProps={menuProps}
                        value={formik.values.specificTime}
                        onChange={handleSpecificTime}
                      >
                        {timeArray.map((time) => (
                          <MenuItem key={time} value={time}>
                            {time}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            <FormControl className={classes.formControl}>
              <div className="select-container">
                {formik.values.payment === "Наличными при получении" && (
                  <img src={moneyIcon} alt="Оплата наличными при получении" />
                )}
                {formik.values.payment === "Картой при получении" && (
                  <img src={creditCardIcon} alt="Оплата картой при получении" />
                )}
                <div>
                  <Select
                    className={classes.select}
                    id="select-payment"
                    name="payment"
                    MenuProps={menuProps}
                    value={formik.values.payment}
                    onChange={formik.handleChange}
                  >
                    <MenuItem value="cache">
                      Оплата наличными при получении
                    </MenuItem>
                    <MenuItem value="card">
                      Оплата картой при получении
                    </MenuItem>
                    <MenuItem value="online">
                      Оплата картой онлайн
                    </MenuItem>
                  </Select>
                </div>
              </div>
            </FormControl>
            {
              (formik.values.payment == "cache" || formik.values.payment == "card") &&
              <>
                <FormControlLabel
                  control={<Checkbox checked={birthday} onChange={(e) => { setBirthday(e.target.checked) }} />}
                  label="У меня день рождения" />
              </>
            }

            <Grid item xs={12}>
              <div className="cart__prompt">
                <p>
                  Ознакомьтесь с{" "}
                  <Modal
                    btnText="информацией о доставке"
                    btnStyle={{
                      display: "inline-block",
                      padding: "0px",
                      fontSize: "14px",
                    }}
                  >
                    <DeliveryInfo />
                  </Modal>{" "}
                  перед совершением заказа
                </p>
              </div>
            </Grid>

            <Grid container justifyContent="center">
              <Button
                type="submit"
                className="cart__submit"
                style={{
                  padding: "0.8em 2em",
                  color: "white",
                  border: "1px solid #1e1e1e",
                  backgroundColor: "#1e1e1e",
                  borderRadius: "6px",
                }}
                hover={{
                  backgroundColor: "white",
                  color: "black",
                }}
              >
                Заказать
              </Button>
            </Grid>
          </form>
        </Grid>
        <Grid className="cart__column" item md={6} sm={8} xs={12}>
          <h2 className="cart__title">Ваш заказ</h2>
          <div className="order">
            <ul className="order__list">
              {Object.keys(cartItems).map((id) => (
                <CartItem key={id} id={id} />
              ))}
            </ul>
            {/* <p className="order__cost">
              Ошибка заказа: {orderError}
            </p> */}
            <p className="order__cost">
              Стоимость заказа: {totalPrice.toLocaleString("ru-RU")} ₽
            </p>
            {(wayToGet == "Доставка" && missingAmount > 0)
              &&
              <>
                <p className="order__cost" style={{ color: "#f00" }}>
                  Стоимость доставки: {missingAmount} ₽
                </p>
                <p className="order__cost">
                  До бесплатной доставки ещё: {missingAmount} ₽
                </p>
              </>
            }
            {
              (wayToGet == "Доставка" && missingAmount == 0 && orderError == "") &&
              <>
                <p className="order__cost" style={{ textDecoration: "line-through" }}>
                  Стоимость доставки: 0₽
                </p>
                <p className="order__cost" style={{ color: "#3aff00" }}>
                  Бесплатная доставка
                </p>
              </>
            }
            {
              (orderError == "price_error" && wayToGet == "Доставка") &&
              <p className="order__cost"><b style={{ color: "#f00" }}>Минимальная сумма доставки 650₽</b></p>
            }
            <b className="order__payment">
              К оплате: {wayToGet == "Доставка" ? countTotalPrice() + deliveryPrice + missingAmount : countTotalPrice()} ₽
            </b>


          </div>
          {/* <div className="cart__slider">
            <h2 className="cart__title">Рекомендуем к&nbsp;вашему заказу</h2>
            <CartSlider />
          </div> */}
        </Grid>
      </Grid>
      {(missingPopup) &&
        <div class="modal">
          <div class="modal__container modal__container--default entered">
            <svg onClick={() => setMissingPopup(false)} class="button modal__close" width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.11768 19.1176L19.8824 5.88235" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.8824 19.1176L6.11768 5.88235" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="modal__body">
              <p style={{ marginBottom: "20px" }}>Для бесплатной доставки необходимо дополнить корзину еще на <b>{missingAmount}₽</b> 💛 </p>
              <p style={{ marginBottom: "40px" }}>Стоимость платной доставки составит {missingAmount}₽</p>
              <a style={{marginBottom: "20px"}} class="cart__back" href="/">К меню</a> <a class="cart__back" href="#" onClick={() => { makeOrder() }}>Платная доставка</a>
            </div>
          </div>
        </div>
      }
      {(zonePopup) &&
        <div class="modal">
          <div class="modal__container modal__container--default entered">
            <svg onClick={() => setZonePopup(false)} class="button modal__close" width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.11768 19.1176L19.8824 5.88235" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.8824 19.1176L6.11768 5.88235" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="modal__body">
              <p style={{ marginTop: "40px" }}>Ваш адрес находится вне зоны доставки, для заказа свяжитесь с оператором по номеру <span style={{ fontWeight: "bold !important" }}>263-01-77</span></p>
            </div>
          </div>
        </div>
      }
      {(timePopup) &&
        <div class="modal">
          <div class="modal__container modal__container--default entered">
            <svg onClick={() => setTimePopup(false)} class="button modal__close" width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.11768 19.1176L19.8824 5.88235" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.8824 19.1176L6.11768 5.88235" stroke="#18181B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            <div class="modal__body">
              <p style={{ marginTop: "40px" }}>К сожалению, мы пока что не можем обработать ваш заказ :(
                <br /> Оставьте заявку на сайте, мы перезвоним как начнем работу, с 10:30!</p>
            </div>
          </div>
        </div>
      }
    </section>
  );
};

CartOrder.propTypes = {
  cartItems: PropTypes.objectOf(PropTypes.ay).isRequired,
  totalPrice: PropTypes.number.isRequired,
  totalPriceWithDiscount: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CartOrder;
