import { useEffect, useState } from 'react';
import './Cake.scss';
import cakeImg from "../../assets/img/cake.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import {
    addToCart,
  } from "../../redux/actions/cart";



const Cake = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [step, setStep] = useState(1);
    const [basket, setBasket] = useState( JSON.parse(localStorage.getItem('basket_tort')) || []);
    const [category, setCategory] = useState(0);
    const [commentsOption, setCommentsOption] = useState( JSON.parse(localStorage.getItem('comments_tort')) || []);
    const [commentOption, setCommenttOption] = useState("");
    const [commentOrder, setCommentOrder] = useState("");
    const [subCategory, setSubCategory] = useState(0);
    
    const { items: mainCategories } = useSelector(
        ({maincategoriestort }) => maincategoriestort
      );

      const { items: subCategories } = useSelector(
        ({subcategoriestort }) => subcategoriestort
      );


      useEffect(() => {
        if(basket.length > 0) {
            setShow(true)
        }
      }, [])
      

    const showCategory = () => {
        if (step == 1) {
            return mainCategories.map((item) => {
                return (
                    <div key={item._id} className='cake-block' onClick={() => chooseCategory(item._id)}>
                        {basket.filter((item1) => item1.category_block._id == item._id).length > 0 ? (
                            <div className="image-container">
                                <img src={`http://31.31.202.13:5001/uploads/maincategoriesuploads/${item.uploadedImage?.path}`} />
                                
                                <div className="after"><CheckIcon className='icon-check' /></div>
                            </div>
                        ) : (<div>
                            <img src={`http://31.31.202.13:5001/uploads/maincategoriesuploads/${item.uploadedImage?.path}`} />

                        </div>)}


                        <p>{item.name}</p>
                    </div>
                )
            })
        } else if (step == 2) {


            return subCategories.filter((item) => item.category == category).map((item) => {
                return (

                    <div key={item._id} className='cake-block' onClick={() => chooseCategory(item._id)}>
                        {basket.filter((item1) => item1._id == item._id).length > 0 ? (
                            <div className="image-container">
                                <img src={`http://31.31.202.13:5001/uploads/subcategoriesuploads/${item.uploadedImage?.path[0]}`} />
                                <div className="after"><CheckIcon className='icon-check' /></div>
                            </div>
                        ) : (<div>
                            <img src={`http://31.31.202.13:5001/uploads/subcategoriesuploads/${item.uploadedImage?.path[0]}`} />

                        </div>)}


                        <p>{item.name}</p>
                    </div>



                )
            })
        } else if (step == 3) {
          
           
       
            
            const category_item = mainCategories.find((item) => item._id == category);
            const item = subCategories.find((item) => item._id == subCategory);
            let text = commentOption.length > 0 ? commentOption : commentsOption.find((item) => item._id == category_item._id) 
            && commentsOption.find((item) => item._id == category_item._id).comment


            return (
                <div className='cake-gallery'>
                    <h3>{item.name}-{item.price} руб.</h3>
                    <Carousel>
                   
                        {item.uploadedImage?.path.map((item) => {
                            return (
                                <div>
                                    <img src={`http://31.31.202.13:5001/uploads/subcategoriesuploads/${item}`} />
                                    
                                </div>
                            )
                        })}

                    </Carousel>

                    <TextField
                        placeholder={`Можете добавить комментарий к разделу - "${category_item.name}"`}
                        multiline
                        className='description-option'
                        maxRows={4}
                        value={text}
                        onChange={(e) => {
                            setCommenttOption(e.target.value || ' ')

                        }
                       }
                    />


                    <Box textAlign='center'>
                        {basket.find((item1) => item1._id == item._id) ? <Button variant="contained" onClick={() => deleleItem(item._id)}>Удалить из списка</Button> : <Button variant="contained" onClick={() => addBasket()}>Добавить в список</Button>}
                    </Box>
                </div>
            )

        }
    }


    const chooseCategory = (id = '') => {

        if (step == 1) {
            setCategory(id)
            setStep(2)
        } if (step == 2) {
            setSubCategory(id)
            setStep(3)
        } else if (step == 3) {
            setStep(2)
        }

    }


    const back = () => {
        if (step == 3) {
            setStep(2)
        } else if (step == 2) {
            setStep(1)
        }
    }

    const addBasket = () => {
        let new_basket = [...basket];
        const category_item = mainCategories.find((item) => item._id == category);
  
        const item = subCategories.find((item) => item._id == subCategory);

        
        if (!category_item.isMore ) {

            new_basket = new_basket.filter((item) => item.category_block._id != category)
        }

        var tempProps = JSON.parse(JSON.stringify(item));
        tempProps.category_block = category_item;
        Object.preventExtensions(tempProps);

      

      
        localStorage.setItem('basket_tort', JSON.stringify([...new_basket, tempProps]));
        setBasket([...new_basket, tempProps])


        if (commentOption.length > 0) {
            if (commentsOption.find((item) => item._id == category_item._id)) {
                commentsOption.find((item) => item._id == category_item._id).comment = commentOption
            } else {
                let new_comment = {
                    _id: category_item._id,
                    name: category_item.name,
                    comment: commentOption
                }

                setCommentsOption([...commentsOption, new_comment])
                localStorage.setItem('comments_tort', JSON.stringify([...commentsOption, new_comment]));
               
            }
        }


        setCommenttOption('')
        setCategory(0)
        setStep(1)
        setSubCategory(0)

    }


    const deleleItem = (id) => {

        let basket_new = basket.filter((item) => item._id != id);

        localStorage.setItem('basket_tort', JSON.stringify(basket_new));
        setBasket(basket_new)
    }


    const basketClear = () => {

        localStorage.removeItem("comments_tort");
        setCommentsOption([])
        localStorage.removeItem("basket_tort");
        setBasket([])
      
    }


    const addFinishedsBasket = () => {
        console.log(basket)
        console.log(commentOrder)
        console.log(commentsOption)
        let basket_text = `Корзина:<br/>`;
        let comments_text = `Комментарии по ингредиентам:<br/>`;
        
        basket.map((item, index) => {
            basket_text += item.category_block.name+"-"+item.name+":"+item.price+`руб.<br/>`
        })
        commentsOption.map((item, index) => {
            comments_text += item.name+`=`+item.comment+`<br/>`
        })

        let id = 1;
        let name = basket_text+`<br/>`+comments_text+`<br/> Итоговая сумма:`+basket.reduce((a, v) => a = a + v.price, 0) +`руб.<br/> Комментарий по заказу:`+commentOrder;
        let image = cakeImg
        let priceEnd = basket.reduce((a, v) => a = a + v.price, 0);
        let taste = ' ';

        dispatch(addToCart({ id, name, image, price: priceEnd, taste }));
  
        localStorage.removeItem("comments_tort");
        setCommentsOption([])
        localStorage.removeItem("basket_tort");
        setBasket([])
    }
   

    return (
        <section className="products" >

            <h2 className="title">Торты</h2>

    

            {show ? (
            
                <Grid container spacing={2} className='cake-main-block'>


<Grid sm={6} className="cake-first-block">
              
                    <div>
                        <div className='cake-header'>
                            {step != 1 && <p onClick={() => back()} style={{ cursor: 'pointer' }}> &lt; Назад</p>}
                        </div>
                        <div className='cake-items'>
                            {showCategory()}

                        </div>
                    </div>


               
                </Grid>
                <Grid sm={6}>
             
                    <div className='cake-header'>
                        {basket.length > 0 && <p onClick={() => basketClear()} style={{ cursor: 'pointer' }}>Очистить список </p>}
                    </div>
                    {basket.length > 0 ? basket.map((item) => {
                        return (
                            <div>
                                <p>{item.category_block.name}: {item.name} ({item.price} руб.) <span className='delete-item' onClick={() => deleleItem(item._id)}>удалить</span></p><br />
                            </div>
                        )
                    }) : "Не добавлена ни одна опция"}

                    {basket.length > 0 &&
                        (
                            <div>
                                
                                <div>
                                    <p ><b style={{fontWeight: 'bold'}}>Ориентировочная итоговая цена: {basket.reduce((a, v) => a = a + v.price, 0)} руб.</b></p>
                                </div>

                                <div>
                                    <TextField
                                        placeholder={`Можете добавить коммментарий к заказу`}
                                        multiline
                                        className='description-order'
                                        maxRows={4}

                                        onChange={(e) => setCommentOrder(e.target.value)}
                                    />
                                </div>


                                <Button variant="contained" className='addbasked' onClick={() => addFinishedsBasket()}>Добавить в корзину</Button>
                            </div>
                        )
                    }
              
                </Grid>
                </Grid>) : (<div className='start-tort'>
            <Box className='buttonStartTort' >
            <Button variant="contained" className='startTort' onClick={() => setShow(true)}  >Собрать торт</Button>
            </Box>
            </div>)}


        </section>
    )
}

export default Cake;