import { useEffect, useRef, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";
import "./CategoriesTwo.scss";

const CategoriesTwo = () => {
  const [active, setActive] = useState(0); 
  const activeId = useSelector(({ activeSection }) => activeSection.id);
  const categoriesRef = useRef();

  useEffect(() => {
    setActive(activeId);
  }, [activeId]);

  return (
    <div className="categories_two" ref={categoriesRef}>
      <ul className="categories_two__list"> 
            <li
              key={uuidv4()}
              className="active"
            >
              <HashLink smooth to={`#${10}`}>
                Меню от Шефа
              </HashLink>
            </li> 
            <li
              key={uuidv4()}
              className="active"
            >
              <HashLink smooth to={`#${7}`}>
                Наборы
              </HashLink>
            </li> 
            <li
              key={uuidv4()}
              className="active"
            >
              <HashLink smooth to={`#${6}`}>
                Роллы и Суши
              </HashLink>
            </li>  
      </ul>
    </div>
  );
};

export default CategoriesTwo;
