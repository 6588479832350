import video from '../../../assets/video/interier.mp4'
import  '../../../components/Showreel/Showreel.scss'
const Interier = () => (
  <div className="modal__body">
    <h3 className="modal__title">Добро пожаловать в ресторан XLFood.  </h3>
 
      <div className="showreel__block">
        {/* {isLoaded && content.uploadedVideo ? ( */}
        <video
          poster={video}
          className="showreel__video"
          autoPlay
          muted
          loop
          playsInline
       
        >
          <source src={video} />
        </video>
        {/* ) : (
          isLoaded && (
            <img
              className="showreel__image"
              src={`${AWS_URL}${content.uploadedImage.path}`}
              alt={content.alt}
            />
          )
        )} */}
      </div>


    <p className="modal__small-text">
      
<p>Место, где комфорт и уют встречаются с вкусной кухней и внимательным сервисом. Наш зал оформлен с большим вниманием к деталям, чтобы создать прекрасную атмосферу для романтических свиданий, дружеских посиделок или корпоративных мероприятий. 
</p><br/>

<p>Наш интерьер подходит для любой ситуации - будь то романтический ужин, дружеские посиделки, деловой обед или корпоративное мероприятие. </p><br/>
<p>Для больших компаний мы предлагаем возможность арендовать весь зал, чтобы каждый гость почувствовал себя комфортно и уютно.  </p><br/>

<p>Приглашаем Вас насладиться приятной атмосферой нашего ресторана и провести время с удовольствием. Наша команда с радостью сделает все, чтобы Ваш визит был незабываемым!</p>

    </p>

  </div>
)

export default Interier












