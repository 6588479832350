import video from '../../../assets/video/lounge.mp4'
import  '../../../components/Showreel/Showreel.scss'




const Lounch = () => (
  <div className="modal__body">
    <h3 className="modal__title">Smokehall - это уютная,но стильная кальянная в центре Красноярска.  </h3>
 
      <div className="showreel__block">
        {/* {isLoaded && content.uploadedVideo ? ( */}
        <video
          poster={video}
          className="showreel__video"
          autoPlay
          muted
          loop
          playsInline
       
        >
          <source src={video} />
        </video>
        {/* ) : (
          isLoaded && (
            <img
              className="showreel__image"
              src={`${AWS_URL}${content.uploadedImage.path}`}
              alt={content.alt}
            />
          )
        )} */}
      </div>
   


    <p className="modal__small-text">
    <ul>Наша лаундж зона оснащена:
<li>-Основным залом с мягкими диванчиками и креслами </li>
<li>-двумя VIP-комнатами на 6 человек </li>
<li>-VIP-комнатной на 2-3 человека</li> </ul><br/>
 


      
<p>В каждой из VIP-комнат есть приставка PlayStation с загруженными играми, что делает их идеальными для дружеских посиделок или просто отдыха. </p><br/>

<p>В Smokehall Вас ждут настоящие мастера своего дела, которые сделают идеальный кальян исходя из Ваших вкусов и предпочтений. Также в ассортименте различные виды табака на любой вкус и крепость. А для ценителей чего-нибудь необычного у нас есть целое меню Авторских кальянов, которое никого не оставит равнодушными </p><br/>

<p>Smokehall - это место, где можно расслабиться, провести время с друзьями или коллегами в уютной атмосфере. Ждем в гости!</p>
</p>
 

  </div>
)

export default Lounch





