import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import {
  addToCart,
  minusCartItem,
  addGiftCount,
  minusGiftCount,
  deleteGifts,
} from "../../redux/actions/cart";
import Button from "../Button/Button";

const ProductPosition = ({
  taste,
  price,
  nameProduct,
  weight,
  image,
  id,
  size,
  gift,
  mainProduct,
  extraProducts,
  setShowExtraModal,
  setShowGiftModal,
}) => {

  const [addedCount, setAddedCount] = React.useState(0);
  const cartItems = useSelector(({ cart }) => cart.items);
  


  useEffect(() => {
    Object.keys(cartItems).map((id1) => {

      if(id1 == id) {
  
        setAddedCount(cartItems[id].count)
      }
      return null;
    })
  }, [])

  const { items: sizesItems } = useSelector(
    ({sizes }) => sizes
  );

  const dispatch = useDispatch();

  const handleGiftCount = (number) => {
    if (number > 0) dispatch(addGiftCount(number));
    if (number < 0) dispatch(minusGiftCount(-number));
  };




  const handlePlusClick = () => {
    setAddedCount((prev) => prev + 1);
    const name = `${nameProduct} - размер ${size}`;
    const sizeNew = !!size ? sizesItems.find((item) => item.name_rus == size).name_eng : '';

    dispatch(addToCart({ id, name, image, price, taste, size: sizeNew, mainProduct }));

    if (
      Object.keys(cartItems).find((item) => item === id) === undefined &&
      extraProducts.length
    ) {
      setShowExtraModal(true);
    }

    if (gift !== null && gift.product.length) {
      setShowGiftModal(true);
      handleGiftCount(gift.count);
    }
  };

  const handleMinusClick = () => {
    setAddedCount((prev) => (prev >= 1 ? prev - 1 : 0));
    dispatch(minusCartItem(id));

    if (gift !== null && gift.product.length) {
      handleGiftCount(-gift.count);
      dispatch(deleteGifts({ prodId: id, count: gift.count }));
    }
  };

  return (
    <div className="product__position">
      <div className="product__position-title">{size}</div>
      {weight && (<div className="product__position-weight">{weight} гр</div>)}
      <div className="product__counter">
        <b className="product__price">{price.toLocaleString("ru-RU")} ₽</b>
        {addedCount > 0 && (
          <>
            <Button
              style={{
                color: "#a7a7a7",
                border: "1px solid #a7a7a7",
                borderRadius: "8px",
                backgroundColor: "#fff",
              }}
              hover={{
                border: "1px solid black",
                color: "black",
              }}
              onClick={handleMinusClick}
            >
              -
            </Button>
            <b>{addedCount}</b>
          </>
        )}
        <Button
          style={{
            color: "#a7a7a7",
            border: "1px solid #a7a7a7",
            borderRadius: "8px",
            backgroundColor: "#fff",
          }}
          hover={{
            border: "1px solid black",
            color: "black",
          }}
          onClick={handlePlusClick}
        >
          +
        </Button>
      </div>
    </div>
  );
};


ProductPosition.propTypes = {
  id: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  taste: PropTypes.string.isRequired,
  weight: PropTypes.string,
  size: PropTypes.string,
  nameProduct: PropTypes.string,
  image: PropTypes.string,
  gift: PropTypes.bool,
  extraProducts: PropTypes.string,
  setShowExtraModal: PropTypes.func.isRequired,
  setShowGiftModal: PropTypes.func.isRequired,
};

ProductPosition.defaultProps = {

  size: null,
  nameProduct: null,
  image: null,
  weight: null,
  gift: null,
  extraProducts: null,
};

export default ProductPosition;
