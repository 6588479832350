const MainCategoriesTortLoaded = payload => ({
    type: 'MAINCATEGORIESTORT_LOADED',
    payload,
  })
  
  const setMainCategoriesTort = payload => ({
    type: 'SET_MAINCATEGORIESTORT',
    payload,
  })
  
  const fetchMainCategoriesTort = () => async dispatch => {
    try {
      dispatch(MainCategoriesTortLoaded(false))
  
      const items = await fetch('/api/maincategoriestort').then(response =>
        response.json(),
      )
  
      dispatch(setMainCategoriesTort(items))
    } catch (error) {
      throw new Error(error)
    }
  }
  
  export default fetchMainCategoriesTort
  