/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import { useElementOnScreen } from "../../hooks/useElementOnScreen";
import Product from "../Product/Product";
import "./ProductsBlock.scss";

const URL = `uploads/`;

const ProductsBlock = React.memo(({ id, title, items }) => {
  const { hash } = useLocation();

  const dispatch = useDispatch();
  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "-50%",
    treshold: 1.0,
  });

  useEffect(() => {
    if (isVisible) {
      dispatch({ type: "SET_ACTIVE_SECTION_ID", payload: id });
    }
  }, [isVisible]);

  useEffect(() => {
    if (hash !== "" && +hash.split("#")[1] === id) {
      containerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <section className="products" id={id} ref={containerRef}>

      <h2 className="title">{title}</h2>
      <ul className="products__list">
        {items.map((product) => (


          <Product
          key={uuidv4()}
          id={product._id}
          name={product.name}
          weight={product.weight}
          composition={product.composition}
          price={product.cost}
          size={product.size}
          image={`uploads/${product.image?.path}`}
          extra={product.extra ? product.extra : null}
          taste={product.taste ? product.taste : null}
          gift={product.gift ? product.gift : null}
        />
        )



         )}
      </ul>
    </section>
  );
});

ProductsBlock.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  items: PropTypes.bool,
};

ProductsBlock.defaultProps = {
  id: null,
  items: [],
};

export default ProductsBlock;
