/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  addToCart,
  deleteGifts,
  minusCartItem,
  removeCartItem,
} from "../../redux/actions/cart";
import Button from "../Button/Button";
import noImage from "../../assets/img/no_image.jpg";

import "./CartItem.scss";

const CartItem = ({ id }) => {
  const dispatch = useDispatch();
  const { image, name, count, price, totalPrice, taste } = useSelector(
    ({ cart }) => cart.items[id]
  );


  const initial = useSelector(({ products }) =>
    products.items.find((item) => item._id === id)
  );
  const [giftCount, setGiftCount] = useState(null);

  useEffect(() => {
    if (initial !== undefined && initial.gift) {
      setGiftCount(initial.gift.count);
    }
  }, [initial]);

  const handleMinusClick = () => {
    if (giftCount !== null)
      dispatch(deleteGifts({ prodId: id, count: giftCount }));
    dispatch(minusCartItem(id));
  };
  const handlePlusClick = () => {
    dispatch(addToCart({ id, name, image, price }));
  };
  const handleRemoveClick = () => {
    if (giftCount !== null)
      dispatch(deleteGifts({ prodId: id, count: giftCount * count }));
    dispatch(removeCartItem(id));
  };

  return (
    <li className="cart-item">
      {image ? (
        <img className="cart-item__image" src={image} alt={name} />
      ) : (
        <img className="cart-item__image" src={noImage} alt={name} />
      )}
      <div>
        <div className="cart-item__top">
          <span className="cart-item__name"> <div
      dangerouslySetInnerHTML={{__html: name}}
    />{} {taste && taste}</span>
          <Button className="cart-item__remove" onClick={handleRemoveClick}>
            Закрыть
          </Button>
        </div>
        <div className="cart-item__bottom">
          {price !== 0 && (
            <div className="cart-item__counter">
              <Button
                style={{
                  color: "#a7a7a7",
                  border: "1px solid #a7a7a7",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                }}
                hover={{
                  border: "1px solid black",
                  color: "black",
                }}
                onClick={handleMinusClick}
              >
                -
              </Button>
              <b>{count}</b>
              <Button
                style={{
                  color: "#a7a7a7",
                  border: "1px solid #a7a7a7",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                }}
                hover={{
                  border: "1px solid black",
                  color: "black",
                }}
                onClick={handlePlusClick}
              >
                +
              </Button>
            </div>
          )}
          <p className="cart-item__price">
            {`${price} x ${count} = ${totalPrice.toLocaleString("ru-RU")} ₽`}
          </p>
        </div>
      </div>
    </li>
  );
};

CartItem.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CartItem;
