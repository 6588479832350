const SubCategoriesTortLoaded = payload => ({
    type: 'SUBCATEGORIESTORT_LOADED',
    payload,
  })
  
  const setSubCategoriesTort = payload => ({
    type: 'SET_SUBCATEGORIESTORT',
    payload,
  })
  
  const fetchSubCategoriesTort = () => async dispatch => {
    try {
      dispatch(SubCategoriesTortLoaded(false))
  
      const items = await fetch('/api/subcategoriestort').then(response =>
        response.json(),
      )
  
      dispatch(setSubCategoriesTort(items))
    } catch (error) {
      throw new Error(error)
    }
  }
  
  export default fetchSubCategoriesTort
  