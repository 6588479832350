/* eslint-disable no-underscore-dangle */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import sendMetrics from "../utils/yandexMetric.js";
import Categories from "../components/Categories/Categories";
import ProductsBlock from "../components/ProductsBlock/ProductsBlock";
import Showreel from "../components/Showreel/Showreel";
import CategoriesTwo from "../components/CategoriesTwo/CategoriesTwo"; 
import Cake from "../components/Cake/Cake";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    sendMetrics("hit", location.pathname);
  }, [location]);

  const { items: categoriesItems, isLoaded: categoriesIsLoaded } = useSelector(
    ({ categories }) => categories
  );
  const { items: productsItems, isLoaded: productsIsLoaded } = useSelector(
    ({ products }) => products
  );

  return (
    <>
      <Helmet>
        <title>XLFood - Меню суши и роллов в Красноярске</title>
        <meta
          name="description"
          content="Скидка при самовывозе 15%! Телефон доставки +79914394924!
          Бесплатная доставка от 500р. Новая доставка суши и роллов в Красноярске.
          Вкусная еда с доставкой на дом - порадуй себя и своих гостей!
          Доставка суши и роллов осуществляется в течение часа!"
        />
        <meta
          name="keywords"
          content="Суши, Роллы, Доставка, Красноярск, Еда, Кафе, Wok, Вок"
        />
      </Helmet>
      <main className="main"> 
        <h1 className="visually-hidden">Главная страница XL Food</h1>
        {categoriesIsLoaded && <Categories />}
        <div className="main__container">
          <Showreel />
          <CategoriesTwo />
          
          <div className="main_blocks"> 
          {productsIsLoaded &&
            categoriesIsLoaded &&
            categoriesItems.map((category) => (
              
              <ProductsBlock
                id={category.index}
                title={category.name}
                key={category._id}
                items={productsItems.filter(
                  (product) => product.category?.index === category?.index
                )}
              />
            ))}
            {/* <Cake /> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
